/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthPasswordInput } from '../models/AuthPasswordInput';
import type { BranchInfo } from '../models/BranchInfo';
import type { BranchInfoPatch } from '../models/BranchInfoPatch';
import type { BranchVersionInfo } from '../models/BranchVersionInfo';
import type { CredentialInfo } from '../models/CredentialInfo';
import type { DeployCredentialAvailableSite } from '../models/DeployCredentialAvailableSite';
import type { DeployCredentialInfo } from '../models/DeployCredentialInfo';
import type { DeploySiteInfo } from '../models/DeploySiteInfo';
import type { DeployVersionToSiteInput } from '../models/DeployVersionToSiteInput';
import type { ExternalAuthLocation } from '../models/ExternalAuthLocation';
import type { NewBranchInput } from '../models/NewBranchInput';
import type { NewDeployCredentialInput } from '../models/NewDeployCredentialInput';
import type { NewDeploySiteInput } from '../models/NewDeploySiteInput';
import type { NewPackage } from '../models/NewPackage';
import type { NewPackageInput } from '../models/NewPackageInput';
import type { NewProjectInput } from '../models/NewProjectInput';
import type { NewProjectInviteInput } from '../models/NewProjectInviteInput';
import type { NewUserSession } from '../models/NewUserSession';
import type { NewUserSessionInput } from '../models/NewUserSessionInput';
import type { NewVersionInput } from '../models/NewVersionInput';
import type { PackageFinalizeInput } from '../models/PackageFinalizeInput';
import type { PackageInfo } from '../models/PackageInfo';
import type { PackageInfoPatch } from '../models/PackageInfoPatch';
import type { ProjectInfo } from '../models/ProjectInfo';
import type { ProjectInfoPatch } from '../models/ProjectInfoPatch';
import type { ProjectInviteInfo } from '../models/ProjectInviteInfo';
import type { ProjectInviteJoinInfo } from '../models/ProjectInviteJoinInfo';
import type { ProjectQuotasInfo } from '../models/ProjectQuotasInfo';
import type { ProjectUserInfo } from '../models/ProjectUserInfo';
import type { ProjectUserInput } from '../models/ProjectUserInput';
import type { SessionInfo } from '../models/SessionInfo';
import type { StoreProjectInfo } from '../models/StoreProjectInfo';
import type { UserCredentialInfo } from '../models/UserCredentialInfo';
import type { UserCredentialPatch } from '../models/UserCredentialPatch';
import type { UserInfo } from '../models/UserInfo';
import type { UserInfoPatch } from '../models/UserInfoPatch';
import type { UserSessionInfo } from '../models/UserSessionInfo';
import type { VersionBranchInfo } from '../models/VersionBranchInfo';
import type { VersionInfo } from '../models/VersionInfo';
import type { VersionInfoPatch } from '../models/VersionInfoPatch';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DefaultService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Authenticate with email and password
   * @returns string
   * @throws ApiError
   */
  public postAuthPassword({
    requestBody,
  }: {
    requestBody?: AuthPasswordInput,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/password',
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
      },
    });
  }
  /**
   * Start authentication with external provider
   * @returns ExternalAuthLocation
   * @throws ApiError
   */
  public postAuthExternalStart({
    provider,
    intent,
  }: {
    provider: 'github' | 'gitlab' | 'steam' | 'itch' | 'twitch' | 'discord',
    intent: 'register' | 'link',
  }): CancelablePromise<ExternalAuthLocation> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/external/start',
      query: {
        'provider': provider,
        'intent': intent,
      },
      errors: {
        400: `Invalid \`intent\` or \`provider\``,
      },
    });
  }
  /**
   * Finish authentication with external provider
   * @returns CredentialInfo
   * @throws ApiError
   */
  public postAuthExternalFinish({
    token,
    code,
  }: {
    token: string,
    code: string,
  }): CancelablePromise<CredentialInfo> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/external/finish',
      query: {
        'token': token,
        'code': code,
      },
      errors: {
        400: `Invalid \`code\` or \`token\``,
      },
    });
  }
  /**
   * Get auth session info
   * @returns SessionInfo
   * @throws ApiError
   */
  public getAuthSession(): CancelablePromise<SessionInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/auth/session',
    });
  }
  /**
   * Clear auth session
   * @returns any
   * @throws ApiError
   */
  public postAuthClear(): CancelablePromise<Array<any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/clear',
    });
  }
  /**
   * Auth user
   * @returns string
   * @throws ApiError
   */
  public postUsersAuth({
    token,
  }: {
    token: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/auth',
      query: {
        'token': token,
      },
      errors: {
        400: `Invalid \`token\``,
      },
    });
  }
  /**
   * Register new user
   * @returns string
   * @throws ApiError
   */
  public postUsersRegister({
    token,
  }: {
    token: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/register',
      query: {
        'token': token,
      },
      errors: {
        400: `Invalid \`token\``,
      },
    });
  }
  /**
   * Get current user info
   * @returns UserInfo
   * @throws ApiError
   */
  public getUsersMe(): CancelablePromise<UserInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/me',
    });
  }
  /**
   * Delete current user
   * @returns void
   * @throws ApiError
   */
  public deleteUsersMe(): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/users/me',
    });
  }
  /**
   * Patch current user info
   * @returns void
   * @throws ApiError
   */
  public patchUsersMe({
    requestBody,
  }: {
    requestBody?: UserInfoPatch,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/users/me',
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
      },
    });
  }
  /**
   * Get current user sessions
   * @returns UserSessionInfo
   * @throws ApiError
   */
  public getUsersMeSessions(): CancelablePromise<Array<UserSessionInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/me/sessions',
    });
  }
  /**
   * Create new current user session
   * @returns NewUserSession
   * @throws ApiError
   */
  public postUsersMeSessions({
    requestBody,
  }: {
    requestBody?: NewUserSessionInput,
  }): CancelablePromise<NewUserSession> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/me/sessions',
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
      },
    });
  }
  /**
   * Delete current user session
   * @returns void
   * @throws ApiError
   */
  public deleteUsersMeSessions({
    session,
  }: {
    session: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/users/me/sessions/{session}',
      path: {
        'session': session,
      },
      errors: {
        404: `\`session\` not found`,
      },
    });
  }
  /**
   * Get current user credentials
   * @returns UserCredentialInfo
   * @throws ApiError
   */
  public getUsersMeCredentials(): CancelablePromise<Array<UserCredentialInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/me/credentials',
    });
  }
  /**
   * Link new credential to user
   * @returns string
   * @throws ApiError
   */
  public postUsersMeCredentials({
    token,
  }: {
    token: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/me/credentials',
      query: {
        'token': token,
      },
      errors: {
        400: `Invalid \`token\``,
      },
    });
  }
  /**
   * Unlink credential from user
   * @returns void
   * @throws ApiError
   */
  public deleteUsersMeCredentials({
    provider,
    name,
  }: {
    provider: 'github' | 'gitlab' | 'steam' | 'itch' | 'twitch' | 'discord',
    name: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/users/me/credentials',
      query: {
        'provider': provider,
        'name': name,
      },
      errors: {
        400: `Invalid \`name\` or \`provider\``,
      },
    });
  }
  /**
   * Patch credential of user
   * @returns void
   * @throws ApiError
   */
  public patchUsersMeCredentials({
    provider,
    name,
    requestBody,
  }: {
    provider: 'github' | 'gitlab' | 'steam' | 'itch' | 'twitch' | 'discord',
    name: string,
    requestBody?: UserCredentialPatch,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/users/me/credentials',
      query: {
        'provider': provider,
        'name': name,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\` or \`name\` or \`provider\``,
      },
    });
  }
  /**
   * Get current user deploy credentials
   * @returns DeployCredentialInfo
   * @throws ApiError
   */
  public getUsersMeDeployCredentials(): CancelablePromise<Array<DeployCredentialInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/me/deploy-credentials',
    });
  }
  /**
   * Add deploy credential to user
   * @returns string
   * @throws ApiError
   */
  public postUsersMeDeployCredentials({
    requestBody,
  }: {
    requestBody?: NewDeployCredentialInput,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/me/deploy-credentials',
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
      },
    });
  }
  /**
   * Delete deploy credential from user
   * @returns void
   * @throws ApiError
   */
  public deleteUsersMeDeployCredentials({
    deployCredential,
  }: {
    deployCredential: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/users/me/deploy-credentials/{deploy-credential}',
      path: {
        'deploy-credential': deployCredential,
      },
      errors: {
        404: `\`deploy-credential\` not found`,
      },
    });
  }
  /**
   * Get available sites from deploy credential
   * @returns DeployCredentialAvailableSite
   * @throws ApiError
   */
  public getUsersMeDeployCredentialsAvailableSites({
    deployCredential,
  }: {
    deployCredential: string,
  }): CancelablePromise<Array<DeployCredentialAvailableSite>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/me/deploy-credentials/{deploy-credential}/available-sites',
      path: {
        'deploy-credential': deployCredential,
      },
      errors: {
        404: `\`deploy-credential\` not found`,
      },
    });
  }
  /**
   * List projects user has access to
   * @returns ProjectInfo
   * @throws ApiError
   */
  public getUsersMeProjects(): CancelablePromise<Array<ProjectInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/me/projects',
    });
  }
  /**
   * Create new project
   * @returns string
   * @throws ApiError
   */
  public postProjects({
    requestBody,
  }: {
    requestBody?: NewProjectInput,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/projects',
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
      },
    });
  }
  /**
   * Get project info
   * @returns ProjectInfo
   * @throws ApiError
   */
  public getProjects({
    project,
  }: {
    project: string,
  }): CancelablePromise<ProjectInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}',
      path: {
        'project': project,
      },
      errors: {
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Delete project
   * @returns void
   * @throws ApiError
   */
  public deleteProjects({
    project,
  }: {
    project: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/projects/{project}',
      path: {
        'project': project,
      },
      errors: {
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Patch project info
   * @returns void
   * @throws ApiError
   */
  public patchProjects({
    project,
    requestBody,
  }: {
    project: string,
    requestBody?: ProjectInfoPatch,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/projects/{project}',
      path: {
        'project': project,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Get project quotas
   * @returns ProjectQuotasInfo
   * @throws ApiError
   */
  public getProjectsQuotas({
    project,
  }: {
    project: string,
  }): CancelablePromise<ProjectQuotasInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/quotas',
      path: {
        'project': project,
      },
      errors: {
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Get project branches
   * @returns BranchInfo
   * @throws ApiError
   */
  public getProjectsBranches({
    project,
  }: {
    project: string,
  }): CancelablePromise<Array<BranchInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/branches',
      path: {
        'project': project,
      },
      errors: {
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Create new branch
   * @returns string
   * @throws ApiError
   */
  public postProjectsBranches({
    project,
    requestBody,
  }: {
    project: string,
    requestBody?: NewBranchInput,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/projects/{project}/branches',
      path: {
        'project': project,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Delete branch
   * @returns void
   * @throws ApiError
   */
  public deleteProjectsBranches({
    project,
    branch,
  }: {
    project: string,
    branch: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/projects/{project}/branches/{branch}',
      path: {
        'project': project,
        'branch': branch,
      },
      errors: {
        404: `\`project\` or \`branch\` not found`,
      },
    });
  }
  /**
   * Patch branch info
   * @returns void
   * @throws ApiError
   */
  public patchProjectsBranches({
    project,
    branch,
    requestBody,
  }: {
    project: string,
    branch: string,
    requestBody?: BranchInfoPatch,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/projects/{project}/branches/{branch}',
      path: {
        'project': project,
        'branch': branch,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
        404: `\`project\` or \`branch\` not found`,
      },
    });
  }
  /**
   * Get branch versions
   * @returns BranchVersionInfo
   * @throws ApiError
   */
  public getProjectsBranchesVersions({
    project,
    branch,
    before,
    count,
  }: {
    project: string,
    branch: string,
    before?: string,
    count?: number,
  }): CancelablePromise<Array<BranchVersionInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/branches/{branch}/versions',
      path: {
        'project': project,
        'branch': branch,
      },
      query: {
        'before': before,
        'count': count,
      },
      errors: {
        400: `Invalid \`count\` or \`before\``,
        404: `\`project\` or \`branch\` not found`,
      },
    });
  }
  /**
   * Publish version to branch
   * @returns void
   * @throws ApiError
   */
  public postProjectsBranchesVersions({
    project,
    branch,
    version,
  }: {
    project: string,
    branch: string,
    version: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/projects/{project}/branches/{branch}/versions/{version}',
      path: {
        'project': project,
        'branch': branch,
        'version': version,
      },
      errors: {
        404: `\`project\` or \`branch\` or \`version\` not found`,
      },
    });
  }
  /**
   * Get project versions
   * @returns VersionInfo
   * @throws ApiError
   */
  public getProjectsVersions({
    project,
    before,
    count,
  }: {
    project: string,
    before?: string,
    count?: number,
  }): CancelablePromise<Array<VersionInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/versions',
      path: {
        'project': project,
      },
      query: {
        'before': before,
        'count': count,
      },
      errors: {
        400: `Invalid \`count\` or \`before\``,
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Create new version
   * @returns string
   * @throws ApiError
   */
  public postProjectsVersions({
    project,
    requestBody,
  }: {
    project: string,
    requestBody?: NewVersionInput,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/projects/{project}/versions',
      path: {
        'project': project,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Get version
   * Get version metadata
   * @returns VersionInfo
   * @throws ApiError
   */
  public getProjectsVersions1({
    project,
    version,
  }: {
    project: string,
    version: string,
  }): CancelablePromise<VersionInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/versions/{version}',
      path: {
        'project': project,
        'version': version,
      },
      errors: {
        404: `\`project\` or \`version\` not found`,
      },
    });
  }
  /**
   * Delete version
   * @returns void
   * @throws ApiError
   */
  public deleteProjectsVersions({
    project,
    version,
  }: {
    project: string,
    version: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/projects/{project}/versions/{version}',
      path: {
        'project': project,
        'version': version,
      },
      errors: {
        404: `\`project\` or \`version\` not found`,
      },
    });
  }
  /**
   * Patch version
   * Patch version metadata
   * @returns void
   * @throws ApiError
   */
  public patchProjectsVersions({
    project,
    version,
    requestBody,
  }: {
    project: string,
    version: string,
    requestBody?: VersionInfoPatch,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/projects/{project}/versions/{version}',
      path: {
        'project': project,
        'version': version,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
        404: `\`project\` or \`version\` not found`,
      },
    });
  }
  /**
   * Get branches of version
   * @returns VersionBranchInfo
   * @throws ApiError
   */
  public getProjectsVersionsBranches({
    project,
    version,
    before,
    count,
  }: {
    project: string,
    version: string,
    before?: string,
    count?: number,
  }): CancelablePromise<Array<VersionBranchInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/versions/{version}/branches',
      path: {
        'project': project,
        'version': version,
      },
      query: {
        'before': before,
        'count': count,
      },
      errors: {
        400: `Invalid \`count\` or \`before\``,
        404: `\`project\` or \`version\` not found`,
      },
    });
  }
  /**
   * Get packages of version
   * @returns PackageInfo
   * @throws ApiError
   */
  public getProjectsVersionsPackages({
    project,
    version,
  }: {
    project: string,
    version: string,
  }): CancelablePromise<Array<PackageInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/versions/{version}/packages',
      path: {
        'project': project,
        'version': version,
      },
      errors: {
        404: `\`project\` or \`version\` not found`,
      },
    });
  }
  /**
   * Deploy version
   * @returns string
   * @throws ApiError
   */
  public postProjectsVersionsDeploy({
    project,
    version,
    requestBody,
  }: {
    project: string,
    version: string,
    requestBody?: DeployVersionToSiteInput,
  }): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/projects/{project}/versions/{version}/deploy',
      path: {
        'project': project,
        'version': version,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
        404: `\`project\` or \`version\` not found`,
      },
    });
  }
  /**
   * Get project packages
   * @returns PackageInfo
   * @throws ApiError
   */
  public getProjectsPackages({
    project,
    before,
    count,
  }: {
    project: string,
    before?: string,
    count?: number,
  }): CancelablePromise<Array<PackageInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/packages',
      path: {
        'project': project,
      },
      query: {
        'before': before,
        'count': count,
      },
      errors: {
        400: `Invalid \`count\` or \`before\``,
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Create new package
   * Initializes new package for upload. Package becomes fully created and visible only after finalization.
   * @returns NewPackage
   * @throws ApiError
   */
  public postProjectsPackages({
    project,
    requestBody,
  }: {
    project: string,
    requestBody?: NewPackageInput,
  }): CancelablePromise<NewPackage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/projects/{project}/packages',
      path: {
        'project': project,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Get package info
   * @returns PackageInfo
   * @throws ApiError
   */
  public getProjectsPackages1({
    project,
    _package,
  }: {
    project: string,
    _package: string,
  }): CancelablePromise<PackageInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/packages/{package}',
      path: {
        'project': project,
        'package': _package,
      },
      errors: {
        404: `\`project\` or \`package\` not found`,
      },
    });
  }
  /**
   * Delete package
   * Delete package
   * @returns void
   * @throws ApiError
   */
  public deleteProjectsPackages({
    project,
    _package,
  }: {
    project: string,
    _package: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/projects/{project}/packages/{package}',
      path: {
        'project': project,
        'package': _package,
      },
      errors: {
        404: `\`project\` or \`package\` not found`,
      },
    });
  }
  /**
   * Patch package info
   * @returns void
   * @throws ApiError
   */
  public patchProjectsPackages({
    project,
    _package,
    requestBody,
  }: {
    project: string,
    _package: string,
    requestBody?: PackageInfoPatch,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/projects/{project}/packages/{package}',
      path: {
        'project': project,
        'package': _package,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
        404: `\`project\` or \`package\` not found`,
      },
    });
  }
  /**
   * Download package
   * @returns string
   * @throws ApiError
   */
  public getProjectsPackagesDownload({
    project,
    _package,
  }: {
    project: string,
    _package: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/packages/{package}/download',
      path: {
        'project': project,
        'package': _package,
      },
      errors: {
        404: `\`project\` or \`package\` not found`,
      },
    });
  }
  /**
   * Finalize package
   * Finalize package, so it can be included in versions. Finalized package is immutable.
   * @returns void
   * @throws ApiError
   */
  public putProjectsPackagesFinalize({
    project,
    _package,
    requestBody,
  }: {
    project: string,
    _package: string,
    requestBody?: PackageFinalizeInput,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/projects/{project}/packages/{package}/finalize',
      path: {
        'project': project,
        'package': _package,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
        404: `\`project\` or \`package\` not found`,
      },
    });
  }
  /**
   * Resolve patch package
   * Resolve patch package, returning full package.
   * @returns string
   * @throws ApiError
   */
  public postProjectsPackagesResolve({
    project,
    _package,
  }: {
    project: string,
    _package: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/projects/{project}/packages/{package}/resolve',
      path: {
        'project': project,
        'package': _package,
      },
      errors: {
        404: `\`project\` or \`package\` not found`,
      },
    });
  }
  /**
   * Get package space url
   * Get space url allowing to play web games
   * @returns string
   * @throws ApiError
   */
  public getProjectsPackagesSpace({
    project,
    _package,
  }: {
    project: string,
    _package: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/packages/{package}/space',
      path: {
        'project': project,
        'package': _package,
      },
      errors: {
        404: `\`project\` or \`package\` not found`,
      },
    });
  }
  /**
   * Get package torrent url
   * Get package torrent url
   * @returns string
   * @throws ApiError
   */
  public getProjectsPackagesTorrent({
    project,
    _package,
  }: {
    project: string,
    _package: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/packages/{package}/torrent',
      path: {
        'project': project,
        'package': _package,
      },
      errors: {
        404: `\`project\` or \`package\` not found`,
      },
    });
  }
  /**
   * Get project users
   * @returns ProjectUserInfo
   * @throws ApiError
   */
  public getProjectsUsers({
    project,
  }: {
    project: string,
  }): CancelablePromise<Array<ProjectUserInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/users',
      path: {
        'project': project,
      },
      errors: {
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Set user participation in project
   * @returns void
   * @throws ApiError
   */
  public putProjectsUsers({
    project,
    requestBody,
  }: {
    project: string,
    requestBody?: ProjectUserInput,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/projects/{project}/users',
      path: {
        'project': project,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Leave project
   * @returns void
   * @throws ApiError
   */
  public postProjectsUsersLeave({
    project,
  }: {
    project: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/projects/{project}/users/leave',
      path: {
        'project': project,
      },
      errors: {
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Get project invites
   * @returns ProjectInviteInfo
   * @throws ApiError
   */
  public getProjectsInvites({
    project,
  }: {
    project: string,
  }): CancelablePromise<Array<ProjectInviteInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/invites',
      path: {
        'project': project,
      },
      errors: {
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Add project invite
   * @returns ProjectInviteInfo
   * @throws ApiError
   */
  public postProjectsInvites({
    project,
    requestBody,
  }: {
    project: string,
    requestBody?: NewProjectInviteInput,
  }): CancelablePromise<ProjectInviteInfo> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/projects/{project}/invites',
      path: {
        'project': project,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Delete project invite
   * @returns void
   * @throws ApiError
   */
  public deleteProjectsInvites({
    project,
    invite,
  }: {
    project: string,
    invite: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/projects/{project}/invites/{invite}',
      path: {
        'project': project,
        'invite': invite,
      },
      errors: {
        404: `\`project\` or \`invite\` not found`,
      },
    });
  }
  /**
   * Get project deploy sites
   * @returns DeploySiteInfo
   * @throws ApiError
   */
  public getProjectsDeploySites({
    project,
  }: {
    project: string,
  }): CancelablePromise<Array<DeploySiteInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{project}/deploy-sites',
      path: {
        'project': project,
      },
      errors: {
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Create new deploy site for project
   * @returns string
   * @throws ApiError
   */
  public postProjectsDeploySites({
    project,
    requestBody,
  }: {
    project: string,
    requestBody?: NewDeploySiteInput,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/projects/{project}/deploy-sites',
      path: {
        'project': project,
      },
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Delete deploy site
   * @returns void
   * @throws ApiError
   */
  public deleteProjectsDeploySites({
    project,
    deploySite,
  }: {
    project: string,
    deploySite: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/projects/{project}/deploy-sites/{deploy_site}',
      path: {
        'project': project,
        'deploy_site': deploySite,
      },
      errors: {
        404: `\`project\` or \`deploy_site\` not found`,
      },
    });
  }
  /**
   * Get store project info
   * @returns StoreProjectInfo
   * @throws ApiError
   */
  public getStoreProjects({
    project,
  }: {
    project: string,
  }): CancelablePromise<StoreProjectInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/store/projects/{project}',
      path: {
        'project': project,
      },
      errors: {
        404: `\`project\` not found`,
      },
    });
  }
  /**
   * Download store project's package
   * @returns string
   * @throws ApiError
   */
  public getStoreProjectsBranchesPackagesDownload({
    project,
    branch,
    _package,
  }: {
    project: string,
    branch: string,
    _package: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/store/projects/{project}/branches/{branch}/packages/{package}/download',
      path: {
        'project': project,
        'branch': branch,
        'package': _package,
      },
      errors: {
        404: `\`project\` or \`branch\` or \`package\` not found`,
      },
    });
  }
  /**
   * Get store package space url
   * Get space url allowing to play web games
   * @returns string
   * @throws ApiError
   */
  public getStoreProjectsBranchesPackagesSpace({
    project,
    branch,
    _package,
  }: {
    project: string,
    branch: string,
    _package: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/store/projects/{project}/branches/{branch}/packages/{package}/space',
      path: {
        'project': project,
        'branch': branch,
        'package': _package,
      },
      errors: {
        404: `\`project\` or \`branch\` or \`package\` not found`,
      },
    });
  }
  /**
   * Get store package torrent url
   * Get store package torrent url
   * @returns string
   * @throws ApiError
   */
  public getStoreProjectsBranchesPackagesTorrent({
    project,
    branch,
    _package,
  }: {
    project: string,
    branch: string,
    _package: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/store/projects/{project}/branches/{branch}/packages/{package}/torrent',
      path: {
        'project': project,
        'branch': branch,
        'package': _package,
      },
      errors: {
        404: `\`project\` or \`branch\` or \`package\` not found`,
      },
    });
  }
  /**
   * Watch job status
   * @returns string
   * @throws ApiError
   */
  public getJobs({
    tokens,
  }: {
    tokens?: Array<string>,
  }): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/jobs',
      query: {
        'tokens': tokens,
      },
      errors: {
        400: `Invalid \`tokens\``,
      },
    });
  }
  /**
   * Get the answer to the ultimate question of life, the universe, and everything
   * @returns string
   * @throws ApiError
   */
  public postJobs(): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/jobs',
    });
  }
  /**
   * Get invite info
   * @returns ProjectInviteJoinInfo
   * @throws ApiError
   */
  public getInvites({
    token,
  }: {
    token: string,
  }): CancelablePromise<ProjectInviteJoinInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/invites',
      query: {
        'token': token,
      },
      errors: {
        400: `Invalid \`token\``,
      },
    });
  }
  /**
   * Join invite
   * @returns string
   * @throws ApiError
   */
  public postInvites({
    token,
  }: {
    token: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/invites',
      query: {
        'token': token,
      },
      errors: {
        400: `Invalid \`token\``,
      },
    });
  }
  /**
   * Provide a VM to play on
   * @returns string
   * @throws ApiError
   */
  public postGameStreamingProvide({
    requestBody,
  }: {
    requestBody?: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/game-streaming/provide',
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
      },
    });
  }
  /**
   * Init play session
   * @returns string
   * @throws ApiError
   */
  public postGameStreamingPlayInit(): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/game-streaming/play/init',
    });
  }
  /**
   * Connect play session
   * @returns void
   * @throws ApiError
   */
  public postGameStreamingPlayConnect({
    requestBody,
  }: {
    requestBody?: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/game-streaming/play/connect',
      body: requestBody,
      mediaType: 'application/json;charset=utf-8',
      errors: {
        400: `Invalid \`body\``,
      },
    });
  }
  /**
   * Get project's branch's versions' package access
   * @returns boolean
   * @throws ApiError
   */
  public getInternalAccessProjectsBranchesVersionsPackages({
    project,
    branch,
    version,
    _package,
  }: {
    project: string,
    branch: string,
    version: string,
    _package: string,
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/internal/access/projects/{project}/branches/{branch}/versions/{version}/packages/{package}',
      path: {
        'project': project,
        'branch': branch,
        'version': version,
        'package': _package,
      },
      errors: {
        404: `\`project\` or \`branch\` or \`version\` or \`package\` not found`,
      },
    });
  }
}
