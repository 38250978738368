import {
  Accessor,
  Component,
  createSignal,
  onMount,
  Show,
  useContext
} from 'solid-js';
import {
  AppPage,
  AuthContext,
  Button,
  JobState,
  Routes
} from '../../components';
import { keyboardEventKeyCodesToLinuxKeyCodes } from '../../components/keymap';

export const RouteExperiment: Component = () => {
  const { api, tokenlessApi } = useContext(AuthContext)!;
  const [jobs, setJobs] = createSignal<Accessor<JobState>[]>([]);

  return <>
    <AppPage
      title="Experiment"
      breadcrumb="Experiment"
      uiClass="experiment"
    />
    <Routes routes={[
      {
        path: 'game-streaming',
        component: () => {
          let refVideo: HTMLVideoElement;
          let dataChannel: RTCDataChannel | undefined;

          enum Command {
            KeyDown = 0,
            KeyUp = 1,
            KeyPress = 2,
            MouseMove = 3,
            MouseDown = 4,
            MouseUp = 5,
          }

          enum MouseButton {
            Left = 0,
            Right = 1,
            Middle = 2,
          }
          const fromMouseEventButton = (button: number): MouseButton | undefined => {
            switch(button) {
            case 0: return MouseButton.Left;
            case 1: return MouseButton.Middle;
            case 2: return MouseButton.Right;
            }
          }

          let lastMouseX: number | undefined;
          let lastMouseY: number | undefined;

          class CommandBuffer {
            constructor(maxSize: number = 16) {
              this.buffer = new ArrayBuffer(maxSize);
              this.dataView = new DataView(this.buffer);
            }

            appendKeyDown(key: number) {
              this.dataView.setUint8(this.offset++, Command.KeyDown);
              this.dataView.setUint8(this.offset++, key);
            }
            appendKeyUp(key: number) {
              this.dataView.setUint8(this.offset++, Command.KeyUp);
              this.dataView.setUint8(this.offset++, key);
            }

            appendMouseMove(x: number, y: number) {
              if(x === lastMouseX && y === lastMouseY) return;

              this.dataView.setUint8(this.offset++, Command.MouseMove);
              this.dataView.setUint16(this.offset, x, true);
              this.offset += 2;
              this.dataView.setUint16(this.offset, y, true);
              this.offset += 2;

              lastMouseX = x;
              lastMouseY = y;
            }

            appendMouseDown(button: number) {
              this.dataView.setUint8(this.offset++, Command.MouseDown);
              this.dataView.setUint8(this.offset++, button);
            }

            appendMouseUp(button: number) {
              this.dataView.setUint8(this.offset++, Command.MouseUp);
              this.dataView.setUint8(this.offset++, button);
            }

            send() {
              if(dataChannel && this.offset > 0) {
                dataChannel.send(this.buffer.slice(0, this.offset));
              }
            }

            buffer: ArrayBuffer;
            dataView: DataView;
            offset = 0;
          }

          const [connectStarted, setConnectStarted] = createSignal(false);

          onMount(() => {
            refVideo.addEventListener('resize', (e) => {
              console.log('video resize', refVideo.videoWidth, refVideo.videoHeight);
              if(refVideo.videoWidth != null && refVideo.videoHeight != null) {
                refVideo.style.width = `${refVideo.videoWidth}px`;
                refVideo.style.height = `${refVideo.videoHeight}px`;
              }
            });

            refVideo.addEventListener('keydown', (e) => {
              e.preventDefault();
              const code = keyboardEventKeyCodesToLinuxKeyCodes.get(e.code);
              console.log('keydown', e.code, e.key, code);
              if(code == null) return;
              const buffer = new CommandBuffer();
              buffer.appendKeyDown(code);
              buffer.send();
            });
            refVideo.addEventListener('keyup', (e) => {
              e.preventDefault();
              const code = keyboardEventKeyCodesToLinuxKeyCodes.get(e.code);
              console.log('keyup', e.code, e.key, code);
              if(code == null) return;
              const buffer = new CommandBuffer();
              buffer.appendKeyUp(code);
              buffer.send();
            });
            refVideo.addEventListener('mousemove', (e) => {
              e.preventDefault();
              const buffer = new CommandBuffer();
              buffer.appendMouseMove(e.offsetX, e.offsetY);
              buffer.send();
            });
            refVideo.addEventListener('mousedown', (e) => {
              e.preventDefault();
              refVideo.focus();
              const button = fromMouseEventButton(e.button);
              if(button == null) return;
              const buffer = new CommandBuffer();
              buffer.appendMouseMove(e.offsetX, e.offsetY);
              buffer.appendMouseDown(button);
              buffer.send();
            });
            refVideo.addEventListener('mouseup', (e) => {
              e.preventDefault();
              const button = fromMouseEventButton(e.button);
              if(button == null) return;
              const buffer = new CommandBuffer();
              buffer.appendMouseMove(e.offsetX, e.offsetY);
              buffer.appendMouseUp(button);
              buffer.send();
            });
            refVideo.addEventListener('click', (e) => {
              e.preventDefault();
            });
            refVideo.addEventListener('dblclick', (e) => {
              e.preventDefault();
            });
            refVideo.addEventListener('contextmenu', (e) => {
              e.preventDefault();
            });
          });
          return <>
            <video ref={refVideo!} autoplay playsinline muted />
            <div class="footer">
              <div class="buttons align_end">
                <Show when={!connectStarted()}>
                <Button onClick={async () => {
                  setConnectStarted(true);

                  // const remoteOffer = await callDialog(PromptDialog, {
                  //   header: 'offer',
                  // });
                  const remoteOfferStr = await tokenlessApi.postGameStreamingPlayInit();

                  const stream = new MediaStream();
                  const peerConnection = new RTCPeerConnection({
                    iceServers: [
                      {
                        urls: 'stun:stun.cloudflare.com:3478',
                      },
                      // {
                      //   urls: [
                      //     'stun:192.168.1.82:3478',
                      //   ],
                      //   username: 'abc',
                      //   credential: 'def',
                      // },
                    ],
                    bundlePolicy: 'max-bundle',
                  });
                  // TEST
                  window.g_peerConnection = peerConnection;
                  peerConnection.addTransceiver('video', {
                    direction: 'recvonly',
                  });
                  // peerConnection.addTransceiver('audio', {
                  //   direction: 'recvonly',
                  // });
                  peerConnection.addEventListener('datachannel', (e) => {
                    dataChannel = e.channel;
                    console.log('dataChannel', dataChannel.label);
                  });
                  peerConnection.addEventListener('track', (e) => {
                    const track = e.track;
                    switch(track.kind) {
                    case 'video':
                      if(stream.getVideoTracks().length <= 0) {
                        console.log('adding video track');
                        stream.addTrack(track);
                        if(!refVideo.srcObject) {
                          console.log('setting src for video');
                          refVideo.srcObject = stream;
                          refVideo.play();
                        }
                      }
                      break;
                    case 'audio':
                      if(stream.getAudioTracks().length <= 0) {
                        stream.addTrack(track);
                      }
                      break;
                    }
                  });
                  peerConnection.addEventListener('connectionstatechange', (e) => {
                    console.log('connectionstatechange', peerConnection.connectionState);
                  });
                  peerConnection.addEventListener('iceconnectionstatechange', (e) => {
                    console.log('iceconnectionstatechange', peerConnection.iceConnectionState);
                    if(peerConnection.iceConnectionState == 'failed') {
                      console.log('restarting ICE');
                      peerConnection.restartIce();
                    }
                  });
                  peerConnection.addEventListener('icegatheringstatechange', (e) => {
                    console.log('icegatheringstatechange', peerConnection.iceGatheringState);
                  });
                  peerConnection.addEventListener('signalingstatechange', (e) => {
                    console.log('signalingstatechange', peerConnection.signalingState);
                  });
                  peerConnection.addEventListener('negotiationneeded', async (e) => {
                    console.log('negotiationneeded');
                  });
                  peerConnection.addEventListener('icecandidate', async (e) => {
                    if(e.candidate) {
                      console.log('candidate', e.candidate);
                    } else {
                      console.log('no more candidates');
                      console.log('localDescription', peerConnection.localDescription!.type);
                      console.log(peerConnection.localDescription!.sdp);
                      await tokenlessApi.postGameStreamingPlayConnect({
                        requestBody: JSON.stringify(peerConnection.localDescription!.toJSON()),
                      });
                    }
                  });

                  const remoteOffer = JSON.parse(remoteOfferStr);
                  console.log('remoteOffer', remoteOffer.type);
                  console.log(remoteOffer.sdp);
                  await peerConnection.setRemoteDescription(remoteOffer);
                  await peerConnection.setLocalDescription();
                }}>Connect</Button>
                <Button onClick={() => {
                  refVideo.requestFullscreen({
                    navigationUI: 'hide',
                  });
                  refVideo.focus();
                }}>Fullscreen</Button>
                </Show>
              </div>
            </div>
          </>;
        },
      },
      /*
      {
        path: 'cloudflare-stream',
        component: () => {
          const { connected } = useContext(LocalApiContext)!;

          let refVideo: HTMLVideoElement;
          return <>
            <div>{connected() ? 'connected' : 'not connected'}</div>
            <video ref={refVideo!} autoplay playsinline />
            <div class="footer">
              <div class="buttons align_end">
                <Button onClick={async () => {
                  const url = 'https://customer-t8rj1oaufa2rza0e.cloudflarestream.com/9638eeb75886f34e6030860a6257a96b/webRTC/play';

                  const stream = new MediaStream();
                  const peerConnection = new RTCPeerConnection({
                    iceServers: [
                      {
                        urls: 'stun:stun.cloudflare.com:3478',
                      },
                    ],
                    bundlePolicy: 'max-bundle',
                  });
                  peerConnection.addTransceiver('video', {
                    direction: 'recvonly',
                  });
                  peerConnection.addTransceiver('audio', {
                    direction: 'recvonly',
                  });
                  peerConnection.addEventListener('track', (e) => {
                    const track = e.track;
                    switch(track.kind) {
                    case 'video':
                      if(stream.getVideoTracks().length <= 0) {
                        stream.addTrack(track);
                      }
                      break;
                    case 'audio':
                      if(stream.getAudioTracks().length <= 0) {
                        stream.addTrack(track);
                      }
                      break;
                    }
                  });
                  peerConnection.addEventListener('connectionstatechange', (e) => {
                    if(peerConnection.connectionState == 'connected') {
                      if(!refVideo.srcObject) {
                        refVideo.srcObject = stream;
                      }
                    }
                  });
                  peerConnection.addEventListener('negotiationneeded', async (e) => {
                    const offer = await peerConnection.createOffer();
                    await peerConnection.setLocalDescription(offer);
                    const ofr = await new Promise<RTCSessionDescription | null>((resolve) => {
                      setTimeout(() => resolve(peerConnection.localDescription), 1000);
                      peerConnection.addEventListener('icegatheringstatechange', (e) => {
                        if(peerConnection.iceGatheringState == 'complete') {
                          resolve(peerConnection.localDescription);
                        }
                      });
                    });
                    if(!ofr) {
                      throw Error('failed to gather ICE candidates for offer');
                    }

                    while(peerConnection.connectionState != 'closed') {
                      const response = await fetch(url, {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                          'content-type': 'application/sdp',
                        },
                        body: ofr.sdp,
                      });
                      if(response.status == 201) {
                        const answerSDP = await response.text();
                        await peerConnection.setRemoteDescription(new RTCSessionDescription({
                          type: 'answer',
                          sdp: answerSDP,
                        }));
                        // return response.headers.get('location');
                        break;
                      } else {
                        console.error(await response.text());
                      }

                      await new Promise((resolve) => setTimeout(resolve, 5000));
                    }
                  });
                }}>Play WHEP</Button>
                <Button onClick={async () => {
                  const displayMedia = await navigator.mediaDevices.getDisplayMedia({
                    video: true,
                    audio: true,
                    monitorTypeSurfaces: 'include',
                    systemAudio: 'include',
                  });
                  refVideo.srcObject = displayMedia;
                  const recorder = new MediaRecorder(displayMedia, {
                    mimeType: 'video/mp4',
                  });
                  const blobs: Blob[] = [];
                  recorder.addEventListener('dataavailable', (e) => {
                    if(e.data.size > 0) {
                      console.log('blob');
                      blobs.push(e.data);
                      if(blobs.length > 5) {
                        blobs.splice(0, blobs.length - 5);
                      }
                    }
                  });
                  recorder.start(1000);
                  setTimeout(() => {
                    console.log('stop');
                    recorder.stop();
                    const blob = new Blob(blobs, {
                      type: 'video/mp4',
                    });
                    const link = document.createElement('a');
                    link.innerText = 'Video';
                    link.href = URL.createObjectURL(blob);
                    document.body.append(link);
                  }, 10000);
                }}>Capture</Button>
                <Button onClick={async () => {
                  const tokens = [];
                  for(let i = 0; i < 5; ++i)
                    tokens.push(await api.postJobs());
                  setJobs(await watchJobs(tokens));
                }}>Test</Button>
              </div>
              <div>
                <For each={jobs()}>{(job) =>
                  <div>
                    <div>{JSON.stringify(job().output)}</div>
                    <div>{JSON.stringify(job().progress)}</div>
                  </div>
                }</For>
              </div>
            </div>
          </>;
        },
      },
      */
      /*
      {
        path: 'issue',
        component: () => <>
          <h1>Does not run on Windows 11<div class="hint">Issue #123</div></h1>
          <div class="highlight ok">
            <p>This issue has been fixed in version <strong>1.2.0</strong>, published on <strong>Steam</strong> and <strong>itch.io</strong>.</p>
          </div>
          <div class="highlight">
            <p>Tried the fixed version? Can you confirm the fix?</p>
            <div class="buttons">
              <Button><FaIcon solid square-check inline ok />Fix actually works!</Button>
              <Button danger><FaIcon solid circle-xmark inline error />Fix doesn't seem to work</Button>
            </div>
            <p />
          </div>
          <p>The game does not launch on Windows 11. It shows an error screen.</p>
          <p><img srcSet={`${new URL('./experiment_test_error.jpg', import.meta.url)} 1x, ${new URL('./experiment_test_error.jpg', import.meta.url)} 1.5x, ${new URL('./experiment_test_error.jpg', import.meta.url)} 2x`} /></p>
          <h2>Linked whines</h2>
          <ul>
            <li><a href="">Crash on Win11</a></li>
            <li><a href="">Crashes on startup</a></li>
          </ul>
          <h2>Crash reports</h2>
          <ul>
            <li><a href="">Whine #134555</a> (256 Kb)</li>
          </ul>
          <div class="footer">
            <div class="buttons align_end">
              <Button danger><FaIcon regular flag inline />Report content</Button>
            </div>
          </div>
        </>,
      },
      */
      /*
      {
        path: 'public',
        component: () => <>
          <h1>Insatia</h1>
          <div class="highlight warning">
            <p>This content is provided by a third-party developer. Kavykhi.Cloud is not responsible for this content. You are downloading and running this content at your own risk.</p>
          </div>
          <table style={{"grid-column": '2 / -2'}}>
            <tbody>
              <tr>
                <td>Developer:</td>
                <td>Insatia Labs</td>
              </tr>
              <tr>
                <td>Platform:</td>
                <td>Windows</td>
              </tr>
            </tbody>
          </table>
          <div class="footer">
            <div class="buttons align_end">
              <Button danger><FaIcon regular flag inline />Report content</Button>
            </div>
          </div>
        </>
      },
      */
      /*
      {
        path: 'windows-code-signing',
        component: () => <>
          <AppPage
            title="Windows Code Signing"
            breadcrumb="Windows Code Signing"
            uiClass="experiment"
          />
          <h1>Windows Code Signing</h1>
          <h2>What is this?</h2>
          <p>Kavykhi Cloud Signing is a compliant solution for signing Windows binaries in the cloud.</p>
          <h2>What purchasing code signing certificate looks like with Kavykhi Cloud Signing?</h2>
          <ul>
            <li>First, you need to purchase an OV (organization validation) or EV (extended validation) code signing certificate from an authorized vendor, such as <a href="https://www.globalsign.com/" target="_blank">GlobalSign</a>.</li>
            <li>After you verify the identity of your organization to the vendor, in order to generate the code signing certificate the vendor will ask you for a CSR (certificate signing request) and attestation information of the hardware token. Both CSR and attestation information will be provided by our cloud, so all you need to do is to copy and paste it into the certificate vendor's website.</li>
            <li>When the vendor generates the certificate, you will need to upload it to our cloud.</li>
            <li>After that you can sign your code using simple form on our website or our API.</li>
          </ul>
          <h2>What if authorized vendor refuses to work with Kavykhi Cloud Signing for some reason, can I get a refund?</h2>
          <p>Sure! In fact, you can get a refund within 30 days of purchasing with no questions asked. However if you encounter technical problem with the issuance of a certificate with our service, we will deeply appreciate if you can describe the issue to us, so we can fix it.</p>
          <h2>Can I export the signing key from the cloud to use it without the cloud?</h2>
          <p>No. The key is generated by and stored in the hardware secure module (HSM), and cannot be exported. The whole idea of the hardware module requirement is that all cryptographic operations are executed by the HSM itself, and the key never leaves it.</p>
          <h2>What code signing API looks like?</h2>
          <p>It is a simple HTTPS POST request, authenticated with your secret key, and with the executable attached in the POST body. The API returns signed executable in the response.</p>
          <h2>Can the API add a secure timestamp to the signature?</h2>
          <p>Yes. This feature is optional and is enabled by default. You can choose from multiple secure timestamping services. It is recommended to leave the feature enabled, unless you want to do timestamping yourself with an unsupported timestamping service.</p>
          <h2>Is it possible to sign an executable by providing only hash of it, without sending the whole file?</h2>
          <p>Currently it is required to send the whole executable to the API.</p>
          <div class="footer"></div>
        </>,
      },
      */
      {
        path: 'table',
        component: () => <>
          <div class="table">
            <div data-value1="123" data-value2="456" />
            <div data-value1="124" data-value2="436" />
            <div data-value1="125" data-value2="16" />
            <div data-value1="126" data-value2="46" />
          </div>
        </>,
      },
    ]} />
  </>;
};
